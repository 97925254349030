import React from "react"

import DatasetDisplay from "../../components/DatasetDisplay"

// content specific imports
import dataGlobal2000AI from "../../data/data-global-2000-ai"
import ogImage from "../../images/data-points/global-2000-ai.png"

// tanstack table
import { createColumnHelper } from "@tanstack/react-table"

const columnHelper = createColumnHelper()
const columns = [
  columnHelper.accessor(row => row["Account Name"], {
    id: "accountName",
    cell: info => (
      <div className="max-w-[100px] truncate">
        <a
          href={info.row.original["domain"]} rel="nofollow"
          target="_blank"
          className="text-blue underline truncate"
        >
          {info.getValue()}
        </a>
      </div>
    ),
    header: "Company",
  }),
  columnHelper.accessor('generative AI" (Yes/Blank)', {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-green-800 bg-green-50 rounded px-1">
          Generative AI
        </span>
      ) : (
        <></>
      ),
    header: "Working on Generative AI",
  }),
  columnHelper.accessor("natural language processing (Yes/Blank)", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-sky-800 bg-sky-50 rounded px-1">
          Natural Language Processing
        </span>
      ) : (
        <></>
      ),
    header: "Natural Language Processing",
  }),
  columnHelper.accessor("deep learning (Yes/Blank)", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-teal-800 bg-teal-50 rounded px-1">
          Deep Learning
        </span>
      ) : (
        <></>
      ),
    header: "Deep Learning",
  }),
  columnHelper.accessor("computer vision (Yes/Blank)", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-pink-800 bg-pink-50 rounded px-1">
          Computer Vision
        </span>
      ) : (
        <></>
      ),
    header: "Computer Vision",
  }),
  columnHelper.accessor("reinforcement learning (Yes/Blank)", {
    cell: info =>
      info.getValue() ? (
        <span className="text-xs text-slate-800 bg-slate-50 rounded px-1">
          Reinforcement Learning
        </span>
      ) : (
        <></>
      ),
    header: "Reinforcement Learning",
  }),
]

const dataContext = {
  title: "Global 2000 companies investing in AI",
  description:
    "10 years on from the deep learning boom kick started by a giant leap in computer vision, and from the recent release of ChatGPT, we wanted to see how the Global 2000 was using new AI techniques.",
  blurb:
    "10 years on from the deep learning boom kick started by a giant leap in computer vision, and from the recent release of ChatGPT, we wanted to see how the Global 2000 was using new AI techniques.",
  ctaText: `Get the whole list of ${dataGlobal2000AI.length} companies and their AI focus, and sync it with your CRM.`,
  slug: "global-2000-ai",
  freeLimit: 50,
  blogPost: (
    <>
      <p>
        Here’s 5 surprising natural language processing applications at Global
        2000 companies 🤖
      </p>
      <p>
        There has been a lot of hype with generative AI and natural language
        processing (NLP)
      </p>
      <p>
        Usually, new technology creates a &quot;solution searching for a
        problem&quot; issue, and while generating marketing content is obvious,
        I wanted to see what large, Global 2000 companies are using NLP for.
        Here&#39;s 5 of the most interesting examples:
      </p>
      <p>
        👉 Leidos, a defence technology company, uses NLP to find new approaches
        and solutions for contract research and development projects
      </p>
      <p>
        👉 eBay uses NLP to create magical, frictionless, personalized and
        trusted experiences for buyers and sellers
      </p>
      <p>
        👉 Sanofi applies machine learning to digital health and
        patient-generated data from wearables.
      </p>
      <p>👉 Medtronic is using NLP to build personal assistant systems.</p>
      <p>
        👉 Cigna is trying to reducing call volume and handle time, lowering
        health costs, and improving health outcomes.
      </p>
      <p>
        That’s the first 50 companies in this dataset. If you&#39;d like to ask
        additional questions (e.g. who has monthly pricing?), integrate this
        into your CRM, or access the rest of the dataset, please{" "}
        <strong>
          <a href="#cta">contact us for a chat</a>!
        </strong>
      </p>
    </>
  ),
  columns: columns,
  published: "Jan 12, 2023",
}

const DataSet = ({ location }) => {
  return (
    <DatasetDisplay
      location={location}
      ogImage={ogImage}
      dataset={dataGlobal2000AI}
      dataContext={dataContext}
    />
  )
}

export default DataSet
