const dataGlobal2000AI = [
  {
    "Account Name": "Microsoft",
    Website: "https://microsoft.com",
    "# Keyword Results": 463,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "NVIDIA",
    Website: "https://nvidia.com",
    "# Keyword Results": 261,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Adobe",
    Website: "https://adobe.com",
    "# Keyword Results": 206,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Amazon",
    Website: "https://www.amazon.co.uk/",
    "# Keyword Results": 150,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Tesla",
    Website: "https://tesla.com",
    "# Keyword Results": 139,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Apple",
    Website: "https://apple.com",
    "# Keyword Results": 133,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Facebook",
    Website: "https://facebook.com",
    "# Keyword Results": 126,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "IBM",
    Website: "http://www.ibm.com/",
    "# Keyword Results": 89,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Baidu",
    Website: "http://www.baidu.com/",
    "# Keyword Results": 79,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Alphabet",
    Website: "https://abc.xyz",
    "# Keyword Results": 69,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Intel",
    Website: "https://intel.com",
    "# Keyword Results": 64,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Samsung",
    Website: "https://www.samsung.com/uk/",
    "# Keyword Results": 41,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Accenture",
    Website: "https://accenture.com",
    "# Keyword Results": 40,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Netflix",
    Website: "https://netflix.com",
    "# Keyword Results": 39,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Twitter",
    Website: "http://www.twitter.com/",
    "# Keyword Results": 37,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Twilio",
    Website: "http://www.twilio.com/",
    "# Keyword Results": 37,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Oracle",
    Website: "http://www.oracle.com/",
    "# Keyword Results": 36,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Qualcomm",
    Website: "https://qualcomm.com",
    "# Keyword Results": 35,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Mitsubishi",
    Website: "https://mitsubishicorp.com",
    "# Keyword Results": 30,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Shopify",
    Website: "https://shopify.com",
    "# Keyword Results": 27,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Infosys",
    Website: "https://infosys.com",
    "# Keyword Results": 24,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "NASDAQ",
    Website: "https://nasdaq.com",
    "# Keyword Results": 24,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "HubSpot",
    Website: "https://www.hubspot.com/",
    "# Keyword Results": 23,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "CarMax",
    Website: "http://www.carmax.com/",
    "# Keyword Results": 22,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Changchun High & New Technology",
    Website:
      "https://www.crunchbase.com/organization/changchun-high-new-technology-industries-group",
    "# Keyword Results": 21,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Autodesk",
    Website: "https://autodesk.com",
    "# Keyword Results": 20,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "MediaTek",
    Website: "http://www.mediatek.com/",
    "# Keyword Results": 19,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Roblox",
    Website: "https://roblox.com",
    "# Keyword Results": 19,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "LG",
    Website: "https://lge.co.kr",
    "# Keyword Results": 19,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Nokia",
    Website: "http://www.nokia.com/",
    "# Keyword Results": 18,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "SAP",
    Website: "https://sap.com",
    "# Keyword Results": 17,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Snowflake",
    Website: "https://snowflake.com",
    "# Keyword Results": 17,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Etsy",
    Website: "https://etsy.com",
    "# Keyword Results": 17,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Siemens",
    Website: "https://siemens.com",
    "# Keyword Results": 16,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "ServiceNow",
    Website: "https://servicenow.com",
    "# Keyword Results": 16,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "VINCI",
    Website: "https://vinci.com",
    "# Keyword Results": 16,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Leidos",
    Website: "http://www.leidos.com/",
    "# Keyword Results": 15,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Sony",
    Website: "http://www.sony.net/",
    "# Keyword Results": 15,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "PayPal",
    Website: "https://paypal.com",
    "# Keyword Results": 15,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Williams",
    Website: "https://www.williamsf1.com/",
    "# Keyword Results": 14,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Morgan Stanley",
    Website: "http://www.morganstanley.com/",
    "# Keyword Results": 13,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Lockheed Martin",
    Website: "http://www.lockheedmartin.com/",
    "# Keyword Results": 13,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Ansys",
    Website: "https://ansys.com",
    "# Keyword Results": 13,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "AirBnB",
    Website: "https://airbnb.com",
    "# Keyword Results": 13,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "eBay",
    Website: "https://www.ebay.co.uk/",
    "# Keyword Results": 12,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Mitsubishi Motors",
    Website: "http://www.mitsubishi-motors.com/",
    "# Keyword Results": 12,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Pinterest",
    Website: "https://pinterest.com",
    "# Keyword Results": 12,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Toyota",
    Website: "http://global.toyota/en",
    "# Keyword Results": 11,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Walmart",
    Website: "https://www.walmart.com/",
    "# Keyword Results": 11,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Fujitsu",
    Website: "https://fujitsu.com",
    "# Keyword Results": 11,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "CGI",
    Website: "https://www.cgi.com/en",
    "# Keyword Results": 11,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Bank of America",
    Website: "http://www.bankofamerica.com/",
    "# Keyword Results": 10,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Target",
    Website: "https://www.target.com/",
    "# Keyword Results": 10,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "AstraZeneca",
    Website: "http://www.astrazeneca.com/",
    "# Keyword Results": 10,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Capgemini",
    Website: "https://capgemini.com",
    "# Keyword Results": 10,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Square",
    Website: "https://squareup.com",
    "# Keyword Results": 10,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "PepsiCo",
    Website: "http://www.pepsico.com/",
    "# Keyword Results": 9,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "VMware",
    Website: "https://vmware.com",
    "# Keyword Results": 9,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "NEC",
    Website: "https://nec.com",
    "# Keyword Results": 9,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Mosaic",
    Website: "https://joinmosaic.com",
    "# Keyword Results": 9,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Metro",
    Website: "https://metro.co.uk",
    "# Keyword Results": 9,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Comcast",
    Website: "http://corporate.comcast.com/",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Sanofi",
    Website: "https://sanofi.com",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Nike",
    Website: "https://nike.com",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "BAE Systems",
    Website: "https://baesystems.com",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Discovery",
    Website: "https://discovery.co.za",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Ferguson",
    Website: "https://ferguson.com",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Naver",
    Website: "http://naver.com/",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Experian",
    Website: "http://www.experianplc.com/",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Check Point Software",
    Website: "http://www.checkpoint.com/",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Tech Mahindra",
    Website: "http://www.techmahindra.com/",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Johnson & Johnson",
    Website: "https://jnj.com",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "AIRBUS",
    Website: "https://www.airbus.com/en",
    "# Keyword Results": 8,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Visa",
    Website: "https://visa.com",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "3M",
    Website: "http://www.3m.com/",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Biogen",
    Website: "http://www.biogen.com/",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Thomson Reuters",
    Website: "https://thomsonreuters.com",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Leonardo",
    Website: "https://stomartstudio.ru",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Kakao",
    Website: "https://kakaocorp.com",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "DocuSign",
    Website: "https://docusign.com",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Hitachi",
    Website: "http://www.hitachi.com/",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Munich Re",
    Website: "https://muenchener.de",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Philips",
    Website: "https://philips.com",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Wayfair",
    Website: "https://wayfair.com",
    "# Keyword Results": 7,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Capital One",
    Website: "http://www.capitalone.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Broadcom",
    Website: "https://www.broadcom.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Nintendo",
    Website: "https://nintendo.com",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Eaton",
    Website: "https://eaton.com",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Boeing",
    Website: "http://www.boeing.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Stellantis",
    Website: "https://stellantis.com",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Bayer",
    Website: "https://bayer.com",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Cognizant",
    Website: "http://www.cognizant.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "TELUS",
    Website: "https://telus.com",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Jacobs",
    Website: "http://www.jacobs.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Hilton",
    Website: "https://hilton-inc.com",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Akamai Technologies",
    Website: "http://www.akamai.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Pfizer",
    Website: "http://www.pfizer.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Eli Lilly",
    Website: "http://www.lilly.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "AT&T",
    Website: "https://www.att.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Wipro",
    Website: "http://www.wipro.com/",
    "# Keyword Results": 6,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Novartis",
    Website: "http://www.novartis.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Unilever",
    Website: "https://www.unilever.co.uk/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Dell Technologies",
    Website: "http://www.dell.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Vodafone",
    Website: "https://www.vodafone.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Mondelez",
    Website: "https://www.mondelezinternational.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Xiaomi",
    Website: "https://mi.com",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "National Grid",
    Website: "http://www.nationalgrid.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Tesco",
    Website: "https://www.tesco.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Deutsche Bank",
    Website: "http://www.db.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Walt Disney",
    Website: "https://thewaltdisneycompany.eu/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Ford Motor",
    Website: "http://www.ford.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Swedbank",
    Website: "http://www.swedbank.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Gilead Sciences",
    Website: "http://www.gilead.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "HCL Technologies",
    Website: "http://www.hcltech.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Mahindra & Mahindra",
    Website: "http://www.mahindra.com/",
    "# Keyword Results": 5,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "UnitedHealth Group",
    Website: "https://unitedhealthgroup.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Coca-Cola",
    Website: "https://coca-cola.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "FedEx",
    Website: "http://www.fedex.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Medtronic",
    Website: "https://medtronic.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "DBS",
    Website:
      "https://www.gov.uk/government/organisations/disclosure-and-barring-service",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "McDonald's",
    Website: "https://www.mcdonalds.com/us/en-us.html",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Dow",
    Website: "https://dow.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Canon",
    Website: "http://global.canon/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Komatsu",
    Website: "http://www.komatsu.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "LabCorp",
    Website: "https://www.labcorp.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Advanced Micro Devices",
    Website: "https://www.amd.com/en",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "London Stock Exchange",
    Website: "https://www.londonstockexchange.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Intuit",
    Website: "http://www.intuit.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Corning",
    Website: "http://www.corning.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "S&P Global",
    Website: "https://www.spglobal.com/en/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Rakuten",
    Website: "https://rakuten.co.jp",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Illumina",
    Website: "http://www.illumina.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Zalando",
    Website: "https://zalando.de",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Fortinet",
    Website: "http://www.fortinet.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Coinbase",
    Website: "http://www.coinbase.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Snap",
    Website: "https://snap.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Workday",
    Website: "http://www.workday.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "CrowdStrike",
    Website: "https://crowdstrike.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "DoorDash",
    Website: "http://www.doordash.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Okta",
    Website: "https://okta.com",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Zscaler",
    Website: "http://www.zscaler.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Marsh & McLennan",
    Website: "https://www.marshmclennan.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Raytheon Technologies",
    Website: "http://www.rtx.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Starbucks",
    Website: "http://www.starbucks.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Synopsys",
    Website: "http://www.synopsys.com/",
    "# Keyword Results": 4,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Cigna",
    Website: "http://www.cigna.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Cisco Systems",
    Website: "https://www.cisco.com/c/en_uk/index.html",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "American Express",
    Website: "http://www.americanexpress.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Amgen",
    Website: "http://www.amgen.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Allstate",
    Website: "http://www.allstate.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Hyundai Motor",
    Website: "http://www.hyundai.com/worldwide/en",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Progressive",
    Website: "https://progressive.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "SK Hynix",
    Website: "http://www.skhynix.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Micron Technology",
    Website: "http://www.micron.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Northrop Grumman",
    Website: "http://www.northropgrumman.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Mitsubishi Electric",
    Website: "http://www.mitsubishielectric.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Tyson Foods",
    Website: "http://www.tysonfoods.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "ExxonMobil",
    Website: "http://corporate.exxonmobil.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Canadian National Railway",
    Website: "http://www.cn.ca/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "BASF",
    Website: "http://www.basf.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Activision Blizzard",
    Website: "http://www.activisionblizzard.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Thales",
    Website: "http://www.thalesgroup.com/en",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Fox",
    Website: "https://fox.com.tr",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Kyocera",
    Website: "http://global.kyocera.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Hewlett Packard Enterprise",
    Website: "https://www.hpe.com/us/en/home.html",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Vertex Pharmaceuticals",
    Website: "http://www.vrtx.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Ball",
    Website: "https://ball.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "Yes",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Tata Motors",
    Website: "http://www.tatamotors.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Reinsurance Group of America",
    Website: "http://www.rgare.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "IQVIA",
    Website: "http://www.iqvia.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "Yes",
  },
  {
    "Account Name": "Motorola Solutions",
    Website: "https://motorolasolutions.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Equinix",
    Website: "https://equinix.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Electronic Arts",
    Website: "https://ea.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "China International Travel Service",
    Website: "http://www.cits.net/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "McCormick",
    Website: "https://www.mccormick.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Xilinx",
    Website: "https://xilinx.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "First Citizens Bank (NC)",
    Website: "https://www.firstcitizens.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Samsung Electro-Mechanics",
    Website: "https://www.samsungsem.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Dentsu",
    Website: "https://dentsu.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Atlassian",
    Website: "http://www.atlassian.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Ulta Beauty",
    Website: "http://www.ulta.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Zillow Group",
    Website: "http://www.zillowgroup.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "HelloFresh",
    Website: "http://www.hellofresh.co.uk/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "XPeng",
    Website: "https://heyxpeng.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "TransUnion",
    Website: "http://www.transunion.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "Yes",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "JP Morgan",
    Website: "http://www.jpmorganchase.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Barclays",
    Website: "http://www.barclays.co.uk/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "LG Electronics",
    Website: "https://lg.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Tata Consultancy Services",
    Website: "http://www.tcs.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "Yes",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Chewy",
    Website: "http://www.chewy.com/",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "Yes",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Roku",
    Website: "https://roku.com",
    "# Keyword Results": 3,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Berkshire Hathaway",
    Website: "http://www.berkshirehathaway.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Aramco",
    Website: "http://www.aramco.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Bank of China",
    Website: "https://boc.cn",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Citigroup",
    Website: "https://citigroup.com",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Alibaba Group",
    Website: "http://www.alibabagroup.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Tencent Holdings",
    Website: "https://www.tencent.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "General Motors",
    Website: "http://www.gm.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "BMW Group",
    Website: "http://www.bmwgroup.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "Yes",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "Taiwan Semiconductor",
    Website: "https://www.taiwansemi.com/",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
  {
    "Account Name": "CITIC",
    Website: "https://citic.org.ec",
    "# Keyword Results": 2,
    "AI generated content (Yes/Blank)": "",
    "computer vision (Yes/Blank)": "",
    "deep learning (Yes/Blank)": "",
    'generative AI" (Yes/Blank)': "Yes",
    "natural language processing (Yes/Blank)": "",
    "neural networks (Yes/Blank)": "",
    "reinforcement learning (Yes/Blank)": "",
  },
]

export default dataGlobal2000AI
